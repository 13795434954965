import React from 'react'
import Hero from '../components/Hero/Hero.js'
import Products from '../components/products/Products.js'
import TopProduct from '../components/topProduct/TopProduct.js'
import Banner from '../components/banner/Banner.js'
import { Helmet } from 'react-helmet'
import Subscribe from '../components/subscribe/Subscribe.js'
import Testimonial from '../components/testimonial/Testimonial.js'

const Home = () => {
  return (
    <div>
      <Helmet>
        <title>Home - X Man </title>
        <meta name="description" content="Welcome to X-Man's homepage!"/>
      </Helmet>
      <Hero/>
      <Products/>
      <TopProduct/>
      <Banner/>
      <Subscribe/>
      <Products/>
      <Testimonial/>
    </div>
  )
}

export default Home
