import React from 'react'
 const BannerImg={
    backgroundImage:`url(${"https://firebasestorage.googleapis.com/v0/b/x-man-images-store.appspot.com/o/subscribe_banner.jpg?alt=media&token=d9a55aee-f213-49a1-9a4d-0dde1e18ee79"})`,
    backgroundPosition:'center',
    backgroundRepeat:'no-repeat',
    backgroundSize:'cover',
    height:'100%',
    width:'100%',
 }
const Subscribe = () => {
  return (
    <div data-aos='zoom-in '
    className='mb-20 bg-gray-100 text-white' style={BannerImg}>
      
      <div className='container backdrop-blur-sm py-10'>
        <div className='space-y-6 max-w-xl mx-auto'>
            <h1 data-aos='fade-up' className='text-2xl !text-center sm:text-left sm:text-4xl font-semibold'>Get Notified About New Products</h1>
            <div className='flex gap-1'>
            <input data-aos='fade-up'
            text='text' placeholder='Enter Your Email' className='w-full p-3 rounded-lg text-black'/>
            <button data-aos='fade-up' className='w-36 p-2 text-white bg-yellow-400 hover:bg-white hover:text-yellow-400 rounded-lg'>Subscribe</button></div>
        </div>
      </div>

    </div>
  )
}

export default Subscribe
