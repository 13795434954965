import React from 'react';
import Slider from 'react-slick';

const imageList = [
  {
    id: 1,
    title: "Upto 20% off on all Formal Products",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin sodales justo in facilisis finibus, urna nisi ultrices lectus, vel pharetra velit.",
    image: "https://firebasestorage.googleapis.com/v0/b/x-man-images-store.appspot.com/o/hero1.png?alt=media&token=c1ce06da-24b5-47dc-a0a4-bc35647fc744",
  },
  {
    id: 2,
    title: "Upto 30% off on all Casual Products",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin sodales justo in facilisis finibus, urna nisi ultrices lectus, vel pharetra velit.",
    image: 'https://firebasestorage.googleapis.com/v0/b/x-man-images-store.appspot.com/o/hero2.png?alt=media&token=cb27001e-ad2a-4825-8590-9c7eff639ddb',
  },
  {
    id: 3,
    title: "Upto 40% off on all Products",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin sodales justo in facilisis finibus, urna nisi ultrices lectus, vel pharetra velit.",
    image: 'https://firebasestorage.googleapis.com/v0/b/x-man-images-store.appspot.com/o/hero.png?alt=media&token=7fb4cf40-cb5b-4179-b512-b15674436a0b',
  }
];

const Hero = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 800,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: 'ease-in-out',
    pauseOnHover: false,
    pauseOnFocus: true,
  };

  return (
    <div className="relative overflow-hidden min-h-[550px] sm:min-h-[650px] bg-gray-100 flex justify-center items-center">
      <div className="h-[700px] w-[700px] bg-red-200 absolute -top-1/2 right-0 rounded-3xl rotate-45 -z-9"></div>
      
      <div className="container pb-8 sm:pb-0">
        <Slider {...settings}>
          {imageList.map((data) => (
            <div key={data.id}>
              <div className="grid grid-cols-1 sm:grid-cols-2 ">
                <div className="flex flex-col justify-center gap-4 pt-12 sm:pt-0 text-center sm:text-left order-2 sm:order-1 relative z-10">
                  <h1
                   data-aos="zoom-out"
                   data-aos-duration="500"
                   data-aos-once="true"
                   className="text-5xl sm:text-6xl lg:text-7xl font-bold">{data.title}</h1>
                  <p
                   data-aos="fade-out"
                   data-aos-duration="500"
                   data-aos-delay='100'
                  className="text-sm">
                    {data.description}
                  </p>
                  <div 
                  className='ps-4'
                   data-aos="fade-out"
                   data-aos-duration="500"
                   data-aos-delay='300'
                  >
                    <button className="bg-gradient-to-r from-red-400 to-red-500 hover:scale-105 duration-200 text-white py-2 px-4 rounded-full">
                      Order Now
                    </button>
                  </div>
                </div>
                <div className="order-1 sm:order-2">
                  <div 
                   data-aos="zoom-in"
                   data-aos-once="true"
                  className="relative z-10">
                    <img
                      src={data.image}
                      alt="hero"
                      className="w-[400px] h-[400px] sm:h-[550px] sm:w-[550px] sm:scale-125 object-contain mx-auto"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Hero;
