import React from 'react'
import { GrSecure } from 'react-icons/gr'
import { CiDeliveryTruck } from 'react-icons/ci'
import { MdLocalOffer, MdPayments } from 'react-icons/md'
const Banner = () => {
  return (
    <div className='min-h-[550px] flex justify-center items-center py-12 sm:py-0'>
<div className='container'>
    <div className='grid grid-cols-1 sm:grid-cols-2 gap-6 items-center'>
<div data-aos='zoom-in'>
    <img src='https://firebasestorage.googleapis.com/v0/b/x-man-images-store.appspot.com/o/banner.jpg?alt=media&token=e0d879f0-e1e5-4ef6-b718-c6fb0cdeff7b'
     alt='Banner' className='max-w-[400px] h-[400px] w-full
    mx-auto drop-shadow-[-10px_10px_12px_rgba(0,0,0,1)] object-cover'/>
</div>

<div className='flex flex-col justify-center gap-6 sm:pt-0'>
<h1 data-aos='fade-up' className='text-3xl sm:text-4xl font-bold'>
    Summer Sale upto 30%  off
</h1>
<p data-aos='fade-up' className='text-sm text-gray-500 tracking-wide leading-5'>
        Lorem ipsum dolor sit amet, 
        consectetur adipiscing elit. 
        Proin sodales, justo in facilisis
         finibus, urna nisi ultrices lectus,
          vel pharetra velit velit vel ipsum. </p>

          <div className='flex flex-col gap-4'>
            <div data-aos='fade-up' className='flex items-center gap-4'>
                <GrSecure className='text-4xl h-12 w-12 shadow-sm p-4 rounded-full bg-violet-100' />
                <p>Quality Products</p>
            </div>
            <div data-aos='fade-up' className='flex items-center gap-4'>
                <CiDeliveryTruck className='text-4xl h-12 w-12 shadow-sm p-4 rounded-full bg-orange-100' />
                <p>Fast Delivery</p>
            </div>
            <div data-aos='fade-up' className='flex items-center gap-4'>
                <MdPayments className='text-4xl h-12 w-12 shadow-sm p-4 rounded-full bg-teal-100' />
                <p>Easy Payment method</p>
            </div>
            <div data-aos='fade-up' className='flex items-center gap-4'>
                <MdLocalOffer className='text-4xl h-12 w-12 shadow-sm p-4 rounded-full bg-red-100' />
                <p>Get Offers</p>
            </div>
          </div>
</div>
    </div>

</div>
    </div>
  )
}

export default Banner
