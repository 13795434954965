import React from 'react'
import { FaStar } from 'react-icons/fa'

const productData=[
    {
id:1,
        title: 'Casual Shirt',
        image: "https://firebasestorage.googleapis.com/v0/b/x-man-images-store.appspot.com/o/topProduct1.png?alt=media&token=e417ed4a-5756-4133-8aa9-f2d1cdd9d97d",
        descrription:"lorem ipsum dolor sit amet, consectet ut labore et dolore magna aliquet et justo vit"    
    },
    {
        id:2,
        title: ' Printed Shirt',
        image: "https://firebasestorage.googleapis.com/v0/b/x-man-images-store.appspot.com/o/topProduct2.png?alt=media&token=237edc34-fcfa-41c2-ad9e-5447dcd3784e",
        descrription:"lorem ipsum dolor sit amet, consectet ut labore et dolore magna aliquet et justo vit"
    },
    {
        id:3,
        title: 'Formal Suite',
        image: "https://firebasestorage.googleapis.com/v0/b/x-man-images-store.appspot.com/o/topProduct1.png?alt=media&token=e417ed4a-5756-4133-8aa9-f2d1cdd9d97d",
        descrription:"lorem ipsum dolor sit amet, consectet ut labore et dolore magna aliquet et justo vit"
    }
]

const TopProduct = () => {
  return (
    <div>
            <div className='container'>
        <div className='text-left mb-24'>
                <p data-aos="fade-up" className='text-sm text-red-500'>Top Rated Products for you</p>
                <h1 data-aos="fade-up" className='text-3xl font-bold'>Best Products</h1>
                <p data-aos="fade-up" className='text-xs text-gray-400'> 
                Lorem ipsum dolor sit amet, consect
      adipiscing elit. Proin sodales, justo in facilisis finibus,
       urna nisi ultrices lectus, vel pharetra velit velit
        vel ipsum.
                </p>
            </div>
<div className=' grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-20 md:gap-5 place-items-center'>

    {productData.map((data)=>(
<div key={data.id}
data-aos='zoom-in'
className='rounded-2xl bg-white hover:bg-black/80 hover:text-white relative shadow-xl
duration-200 group max-w-[300px]'>
    <div className='h-[100px]'>
    <img src={data.image} 
    className='max-w-[140px] block mx-auto transform -translate-y-20 group-hover:scale-105 duration-300  drop-shadow-md'
    alt='TopProduct'/>
    
     </div>
     <div className='p-4 text-center'>
<div className='w-full flex items-center justify-center gap-1'>
    <FaStar className='text-yellow-500'/>
    <FaStar className='text-yellow-500'/>
    <FaStar className='text-yellow-500'/>
    <FaStar className='text-yellow-500'/>
</div>
<h1 className='text-xl font-bold'>{data.title}</h1>
<p className='text-gray-500 group-hover:text-white duration-300 text-sm line-clamp-2'>{data.descrription}</p>
<button className='bg-yellow-400 hover:scale-105 duration-300 text-white py-1 px-4
rounded-full mt-4 group-hover:bg-white group-hover:text-yellow-400'>
    Order Now
</button>
     </div>       
     </div>       
    ))}
</div>

            </div>
    </div>
  )
}

export default TopProduct
