    import React from 'react'
    import { Link } from 'react-router-dom'
    import { FaFacebook, FaInstagram, FaLinkedinIn, FaLocationArrow, FaMobileAlt } from 'react-icons/fa'

    const BannerImg = {
        backgroundImage: `url(${'https://firebasestorage.googleapis.com/v0/b/x-man-images-store.appspot.com/o/footer_bg.jpg?alt=media&token=33a7194d-486e-475b-aa7e-a65ddee3e04f'})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '100%',
        width: '100%',
    }

    const QuickLinks = [
        {
            label: 'Collection',
            links: '/#',
        },
        {
            label: 'Sale',
            links: '/#',

        },
        {
            label: 'Shop',
            links: '/#',

        },
        {

            label: 'FAQ',
            links: '/#',
        },
    ]
    const FooterLinks = [
        {
            label: 'Home',
            links: '/',
        },
        {
            label: 'About Us',
            links: '/about-us',

        },
        {

            label: 'Contact Us',
            links: '/contact-us',
        },
        {

            label: 'Blogs',
            links: '/blogs',
        }
    ]

    const Footer = () => {
        return (
            <div style={BannerImg} className='text-white'>
                <div className='container'>
                    <div data-aos='zoom-in' className='grid md:grid-cols-3 pb-24 pt-5'>
                        <div className='py-8 px-4'>
                            <h1 className='sm:text-3xl text-xl font-bold sm:text-left text-justify mb-3 flex items-center gap-2'>
                                <img src="https://firebasestorage.googleapis.com/v0/b/x-man-images-store.appspot.com/o/Logo.png?alt=media&token=454e5328-3da5-44ac-b3c8-caf935dfe9ca"
                                 alt='logo' className='max-w-[50px]' />Man</h1>
                            <p> We are a leading fashion brand offering the latest trends in clothing. Discover our collection and enjoy fast shipping and easy returns.
                            </p>
                        </div>

                        <div className='grid grid-cols-2 sm:grid-cols-3 col-span-2 md:pl-10'>
                            <div>
                                <div className='py-8 px-4'>
                                    <h1 className='sm:text-xl text:xl font-bold sm:text-left text-justify mb-3'>

                                        Useful Links
                                    </h1>
                                    <ul className='flex flex-col gap-3'>
                                        {FooterLinks.map((data) => (
                                            <li className='cursor-pointer hover:text-yellow-400 hover:translate-x-1 duration-300 text-gray-200' key={data.label}>

                                                <span>{data.label}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            <div>
                                <div className='py-8 px-4'>
                                    <h1 className='sm:text-xl text:xl font-bold sm:text-left text-justify mb-3'>

                                        Quick Links
                                    </h1>
                                    <ul className='flex flex-col gap-3'>
                                        {QuickLinks.map((data) => (
                                            <li className='cursor-pointer hover:text-yellow-400 hover:translate-x-1 duration-300 text-gray-200' key={data.label}>

                                                <span>{data.label}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div>
                            <div className='flex items-center gap-3 mt-6'>
                                <Link to=''>
                                    <FaInstagram className='text-3xl' />
                                </Link>

                                <Link to=''>
                                    <FaFacebook className='text-3xl' />
                                </Link>
                                <Link to=''>
                                    <FaLinkedinIn className='text-3xl' />
                                </Link>
                            </div>
                            <div>
                                <div className='mt-6'>
                                    <div className='flex items-center gap-3 mt-6'>

                                        <FaLocationArrow />
                                        <p>Lahore, Punjab Pakistan</p>
                                    </div>
                                    <div className='flex items-center gap-3 mt-6'>

                                        <FaMobileAlt />
                                        <p>+92 123456789</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>

                        <div className=" text-center text-sm">
        &copy; 2024 X Man. All rights reserved.
      </div>

                    </div>
                </div>

            </div>
        )
    }

    export default Footer
