import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaShoppingCart, FaUser, FaBars, FaTimes } from 'react-icons/fa';
import { IoSearch } from 'react-icons/io5';

const DropdownLinks = [
  { id: 1, label: 'Trending Products', path: '/trending-products' },
  { id: 2, label: 'Best Selling', path: '/best-selling' },
  { id: 3, label: 'Top Rated', path: '/top-rated' },
];

const DropdownFormals = [
  { id: 1, label: 'Formal Shirts', path: '/formals/formal-shirts' },
  { id: 2, label: 'Dress Pants', path: '/formals/dress-pants' },
  { id: 3, label: 'Blazers', path: '/formals/blazers' },
  { id: 4, label: 'Suits 2PIC & 3PIC', path: '/formals/suits-2pic&3pic' },
  { id: 5, label: 'Formalf Waistcoat', path: '/formals/formal-waistcoat' },
];

const DropdownCasuals = [
  { id: 1, label: 'Polos', path: '/casuals/polos' },
  { id: 2, label: 'Casual Shirts', path: '/casuals/casual-shirts' },
  { id: 3, label: 'T-Shirts', path: '/casuals/t-shirts' },
];

const DropdownBottoms = [
  { id: 1, label: 'Jeans', path: '/bottoms/jeans' },
  { id: 2, label: 'Shorts', path: '/bottoms/shorts' },
  { id: 3, label: 'Chinos', path: '/bottoms/chinos' },
  { id: 4, label: 'Basic 5 Pocket', path: '/bottoms/basic-5-pocket' },
];

const DropdownTehwar = [
  { id: 1, label: 'Kurta', path: '/tehwar/kurta' },
  { id: 2, label: 'Sherwani', path: '/tehwar/sherwani' },
  { id: 3, label: 'Shalwar Kameez', path: '/tehwar/shalwar-kameez' },
  { id: 4, label: 'Kurta Trouser', path: '/tehwar/kurta-trouser' },
  { id: 5, label: 'Waistcoat', path: '/tehwar/tehwar-waistcoat' },
  { id: 6, label: 'Trouser', path: '/tehwar/trouser' },
  { id: 7, label: 'Shalwar', path: '/tehwar/shalwar' },
];

const NavBar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  return (
    <div className="shadow-md bg-white duration-200 relative z-40">
      {/* Upper NavBar */}
      <div className="bg-red-200 py-1">
        <div className="container mx-auto flex justify-between items-center px-4">
          <div className="flex items-center">
            <Link to="/" className="flex items-center">
              <img src="https://firebasestorage.googleapis.com/v0/b/x-man-images-store.appspot.com/o/Logo.png?alt=media&token=454e5328-3da5-44ac-b3c8-caf935dfe9ca" className="w-12 h-12" alt="Logo" />
               <span className="text-2xl font-bold">Man</span>
            </Link>
          </div>
          <div className="flex items-center gap-2">
            <div className="relative hidden sm:block">
              <input
                type="text"
                placeholder="Search...."
                className="sm:w-[300px] lg:w-[350px] transition-all duration-200 rounded-full border border-gray-300 px-2 py-1 focus:outline-none focus:border-1 focus:border-red-400"
              />
              <IoSearch className="text-gray-500 absolute top-1/2 transform -translate-y-1/2 right-3" />
            </div>
            <button
              onClick={() => alert('Ordering not Available Yet')}
              className="bg-gradient-to-r from-red-400 to-red-500 transition-all duration-200 text-white py-1 px-4 rounded-full flex items-center gap-3 group"
            >
              <span className="group-hover:block hidden transition-all duration-200">Order</span>
              <FaShoppingCart className="group-hover:hidden text-xl text-white drop-shadow-sm cursor-pointer" />
            </button>
            <button className="bg-gradient-to-r from-teal-400 to-teal-500 transition-all duration-200 text-white py-1 px-4 rounded-full flex items-center gap-3 group">
              <span className="group-hover:block hidden transition-all duration-200">LogIn</span>
              <FaUser className="text-xl text-white drop-shadow-sm cursor-pointer group-hover:hidden" />
            </button>
            <button className="sm:hidden block text-2xl text-gray-700" onClick={toggleMobileMenu}>
              {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
            </button>
          </div>
        </div>
      </div>
      {/* Lower NavBar */}
      <div className={`justify-center px-2 sm:flex ${isMobileMenuOpen ? 'block' : 'hidden'} sm:block`}>
        <ul className="flex flex-col sm:flex-row items-center ">
          <li className="py-2">
            <Link to="/" className="inline-block hover:text-red-400 underline-animation duration-200 items-center ms-8 sm:ms-6">
              Home
            </Link>
          </li>
          <li className="py-2">
            <Link to="/new-collection" className="inline-block hover:text-red-400 underline-animation duration-200 items-center ms-8 sm:ms-6">
             New Collection
            </Link>
          </li>
          {/* Formals */}
          <li className=" group cursor-pointer ms-8 sm:ms-6">
            <div className=" py-2">
              <Link to='/formals' className='hover:text-red-400 duration-100'>Formals</Link>
            </div>
            <div className='hidden sm:block'>
            <div className="absolute z-[9999] border-2 hidden group-hover:block w-[150px] rounded-md bg-white p-2 text-black shadow-md">
              <ul>
                {DropdownFormals.map((data) => (
                  <li key={data.id}>
                    <Link to={data.path} className="inline-block w-full rounded-md p-2 hover:text-red-400 duration-300">
                      {data.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            </div>
          </li>
          {/* Casuals */}
          <li className=" group cursor-pointer ms-8 sm:ms-6">
            <div className=" py-2">
              <Link to="/casuals" className='hover:text-red-400 duration-100'>Casuals</Link>
            </div>
            <div className='hidden sm:block'>
            <div className="absolute z-[9999] border-2 hidden group-hover:block w-[150px] rounded-md bg-white p-2 text-black shadow-md">
              <ul>
                {DropdownCasuals.map((data) => (
                  <li key={data.id}>
                    <Link to={data.path} className="inline-block w-full rounded-md p-2 hover:text-red-400 duration-300">
                      {data.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            </div>
          </li>
          {/* Bottoms */}
          <li className=" group cursor-pointer ms-8 sm:ms-6">
            <div className=" py-2">
              <Link to="/bottoms" className='hover:text-red-400 duration-100'>Bottoms</Link>
            </div>
            <div className='hidden sm:block'>
            <div className="absolute z-[9999] border-2 hidden group-hover:block w-[150px] rounded-md bg-white p-2 text-black shadow-md">
              <ul>
                {DropdownBottoms.map((data) => (
                  <li key={data.id}>
                    <Link to={data.path} className="inline-block w-full rounded-md p-2 hover:text-red-400 duration-300">
                      {data.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            </div>
          </li>
          {/* Tehwar */}
          <li className=" group cursor-pointer ms-8 sm:ms-6">
            <div className=" py-2">
              <Link to='/tehwar' className='hover:text-red-400 duration-100'>Tehwar</Link>
            </div>
            <div className='hidden sm:block'>
            <div className="absolute z-[9999] hidden border-2 group-hover:block w-[150px] rounded-md bg-white p-2 text-black shadow-md">
              <ul>
                {DropdownTehwar.map((data) => (
                  <li key={data.id}>
                    <Link to={data.path} className="inline-block w-full rounded-md p-2 hover:text-red-400 duration-300">
                      {data.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            </div>
          </li>
          {/* Trending */}
          <li className=" group cursor-pointer ms-8 sm:ms-6">
            <div className=" py-2">
              <Link to='/trending'className='hover:text-red-400 duration-100'>Trending</Link>
            </div>
            <div className='hidden sm:block'>
            <div className="absolute z-[9999] border-2  hidden group-hover:block  rounded-md bg-white p-2 text-black shadow-md">
              <ul>
                {DropdownLinks.map((data) => (
                  <li key={data.id}>
                    <Link to={data.path} className="inline-block w-full rounded-md p-2 hover:text-red-400 duration-300">
                      {data.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            </div>
          </li>
          {/* About Us */}
          <li className=" group cursor-pointer  ms-8 sm:ms-6">
            <div className=" py-2">
              <Link to='/about-us' className='hover:text-red-400 duration-100'>About</Link>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NavBar;
