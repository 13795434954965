import React from 'react'
import { FaStar } from 'react-icons/fa'

const productList=[
    {
        id: 1,
        title: 'Casual Mens',
        image: 'https://firebasestorage.googleapis.com/v0/b/x-man-images-store.appspot.com/o/product1.jpg?alt=media&token=0ac7ef4d-af7d-4e65-b6e9-029a837d35be',
        rating: "5.0",    
        aosDelay:'0',
    },
    {
        id: 2,
        title: 'Formal Mens',
        image: "https://firebasestorage.googleapis.com/v0/b/x-man-images-store.appspot.com/o/product4.jpg?alt=media&token=38d0d306-03aa-4c33-828e-9c682b2d606f",
        rating: "4.9",    
        aosDelay:'100',
    },
    {

        id: 3,
        title: 'Casual Mens',
        image: "https://firebasestorage.googleapis.com/v0/b/x-man-images-store.appspot.com/o/product2.jpg?alt=media&token=d2e286b8-a528-43a0-b743-513487dbed9f",
        rating: "4.8",    
        aosDelay:'200',
    },

    {
        id: 4,
        title: 'Formal Mens',
        image: 'https://firebasestorage.googleapis.com/v0/b/x-man-images-store.appspot.com/o/hero1.png?alt=media&token=c1ce06da-24b5-47dc-a0a4-bc35647fc744',
        rating: "4.7",    
        aosDelay:'300',
    },
    {
        id: 5,
        title: 'Casual Mens',
        image: "https://firebasestorage.googleapis.com/v0/b/x-man-images-store.appspot.com/o/product3.jpg?alt=media&token=226a2b99-568d-4acc-9486-53a3cae570f8",
        rating: "4.5",    
        aosDelay:'400',
    }
]

const Products = () => {
  return (
    <div className='mt-14 mb-12'>
        <div className='container'>
            <div className='text-center mb-10 max-w-[600px] mx-auto'>
                <p data-aos="fade-up" className='text-sm text-red-500'>Top Selling Products for you</p>
                <h1 data-aos="fade-up" className='text-3xl font-bold'>Products</h1>
                <p data-aos="fade-up" className='text-xs text-gray-400'> 
                Lorem ipsum dolor sit amet, consect
      adipiscing elit. Proin sodales, justo in facilisis finibus,
       urna nisi ultrices lectus, vel pharetra velit velit
        vel ipsum.
                </p>
            </div>
<div>
    <div className='grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 place-items-center gap-5'>
{productList.map((data)=>(
    <div key={data.id}
    data-aos="fade-up"
    data-aos-delay={data.aosDelay}

    className='space-y-3'> 
    <img src={data.image } alt='Products' className='h-[220px] w-[150px]
       object-cover rounded-md '/>

       <div> 
        <h3 className='font-semibold'>{data.title}</h3>
        <div className='flex items-center gap-1'>
            <FaStar className=' text-yellow-400' />
            <span>{data.rating}</span>
        </div>
       </div>
    
    
    </div>
))}
 </div>

<div>
    <div data-aos='fade-up' className='flex justify-center'>
    <button className='bg-gradient-to-r from-red-500 to-red-600 mt-10 text-white py-2 px-4 rounded-full'>
        View All Products
    </button>
    </div>
 </div>
</div>

</div> 


        </div>
      

  )
}

export default Products
