import React from 'react'
import Slider from 'react-slick'



  

const testimonialList=[
 

  {
    id: 1,
    name: 'John Doe',
    image: 'https://firebasestorage.googleapis.com/v0/b/x-man-images-store.appspot.com/o/testimonial.jpg?alt=media&token=e9879ae7-5c16-4aa8-9434-7df83cf95251',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin sodales, justo in facilisis finibus, urna nisi ultrices lectus, vel pharetra velit velit vel ipsum. Sed euismod, arcu vel scelerisque sagittis, mauris neque consectetur tellus, non facilisis velit dolor a neque.',
  },
  {
    id: 2,
    name: 'Jane Doe',
    image: "https://firebasestorage.googleapis.com/v0/b/x-man-images-store.appspot.com/o/testimonial2.jpg?alt=media&token=bfb96314-de37-4b59-a55c-560aa005f2cb",
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin sodales, justo in facilisis finibus, urna nisi ultrices lectus, vel pharetra velit velit vel ipsum. Sed euismod, arcu vel scelerisque sagittis, mauris neque consectetur tellus, non facilisis velit dolor a neque.',
  },
  {
    id: 3,
    name: 'Mike Doe',
    image: "https://firebasestorage.googleapis.com/v0/b/x-man-images-store.appspot.com/o/testimonial3.jpg?alt=media&token=69803c0f-de0f-4ee1-be9c-ed912132a20d",
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin sodales, justo in facilisis finibus, urna nisi ultrices lectus, vel pharetra velit velit vel ipsum. Sed euismod, arcu vel scelerisque sagittis, mauris neque consectetur tellus, non facilisis velit dolor a neque.',
  },
  {
    id: 4,
    name: 'Sarah Doe',
    image: "https://firebasestorage.googleapis.com/v0/b/x-man-images-store.appspot.com/o/testimonial4.jpg?alt=media&token=86c2da3a-5a5a-4392-8b06-365a76462624",
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin sodales, justo in facilisis finibus, urna nisi ultrices lectus, vel pharetra velit velit vel ipsum. Sed euismod, arcu vel scelerisque sagittis, mauris neque consectetur tellus, non facilisis velit dolor a neque.',
  },
  {
    id: 5,
    name: 'Tom Doe',
    image: 'https://firebasestorage.googleapis.com/v0/b/x-man-images-store.appspot.com/o/testimonial5.jpg?alt=media&token=7421b626-4e8f-44b8-b148-d32d7e05e8a4',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin sodales, justo in facilisis finibus, urna nisi ultrices lectus, vel pharetra velit velit vel ipsum. Sed euismod, arcu vel scelerisque sagittis, mauris neque consectetur tellus, non facilisis velit dolor a neque.',
  }
]

const Testimonial = () => {

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    pauseOnHover: true,
    pauseOnFocus: true,
    responsive:[
      {
        breakpoint: 10000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide:2,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };

  return (
    <div className='py-10 mb-10'>
      <div className='container'>
      <div className='text-center mb-10 max-w-[600px] mx-auto'>
                <p data-aos="fade-up" className='text-sm text-red-500'>What our customers are saying</p>
                <h1 data-aos="fade-up" className='text-3xl font-bold'>Testimonials</h1>
                <p data-aos="fade-up" className='text-xs text-gray-400'> 
                Lorem ipsum dolor sit amet, consect
      adipiscing elit. Proin sodales, justo in facilisis finibus,
       urna nisi ultrices lectus, vel pharetra velit velit
        vel ipsum.
                </p>
            </div>
            <div data-aos='zoom-in'>
<Slider {...settings}>
{testimonialList.map((data)=>(
  <div className='my-6'> 
    <div key={data.id} 
  className='flex flex-col gap-4 shadow-lg py-8 px-6 mx-4 rounded-xl bg-yellow-500/10 relative'>
    <div className='mb-4'>
      <img src={data.image} alt='testimonial' 
      className='rounded-full w-20 h-20'/>
    </div>
    <div className='flex flex-col items-center gap-4'>
      <div className='space-y-3'>
      <p className='text-xs text-gray-500'>{data.text}</p>
      <h1 className='text-xl font-bold text-black/80'>{data.name}</h1>
      </div>
    </div>
    <p className='text-black/20 text-9xl font-serif absolute -top-4 right-0'>
    ,,
    </p>
  </div>
     </div>
  
))}
</Slider>
            </div>
      </div>
      
    </div>
  )
}

export default Testimonial
