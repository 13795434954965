import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NavBar from './components/navbar/NavBar';
import NotFoundPage from './Pages/NotFoundPage';
import Home from './Pages/Home.js';
import Aos from 'aos';
import "aos/dist/aos.css"
import React from 'react';
import Footer from './components/footer/Footer.js';

function App() {
  React.useEffect(()=>{
    Aos.init({
      offset:100,
      duration:800,
      easing:'ease-in-sine',
      delay:100,
    });
    Aos.refresh();
  },[]);
  return (
  <>
  <BrowserRouter>
  <NavBar/>
  <Routes>
    <Route path="/" element={<Home/>} />
  <Route path="*" element={<NotFoundPage/>}/>
  </Routes>
  <Footer/>
  </BrowserRouter>
  </>
  );
}

export default App;
